import { ShopId } from 'constants/shop';
import { CatGeneralCdpHandle } from 'models/collection/constants/cat-general-cdp';
import { DogGeneralCdpHandle } from 'models/collection/constants/dog-general-cdp';

/**
 * Quick fix for cat personalization title.
 * we ignore specific food type cdp
 */
const shouldIgnoreSubtitle = ({
  handle,
  shopId,
}: {
  handle?: string;
  shopId: ShopId;
}) => {
  /**
   * Due to data availability, CH shop ignores dog general pages too
   */
  const handles = [
    ...Object.values(CatGeneralCdpHandle),
    ...(shopId === ShopId.CH ? Object.values(DogGeneralCdpHandle) : []),
  ] as Array<string>;

  if (!handle) return false;

  return handles.includes(handle);
};

export default shouldIgnoreSubtitle;
