import { getJudgeMeRatings } from 'api/judge-me';
import IconStarFilled from 'assets/icons/star-filled.svg';
import IconStarHalf from 'assets/icons/star-half.svg';
import IconStar from 'assets/icons/star.svg';
import cn from 'classnames';
import { useConfig } from 'config';
import { useReviewsTest } from 'contexts/reviews-test';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useReviewsCacheStore } from 'stores/reviews-cache';
import useSWR from 'swr';
import { parseReviews } from 'utils/judge-me';
interface PropTypes {
  hideNumberOfReviews?: boolean;
  numberDisplayType?: 'number' | 'unit';
  productId: string | number;
  starSize?: 'small' | 'medium';
  onClick?: () => void;
}

/**
 * Display JudgeMe ratings.
 * In order to reduce the request to API, the component priorities to utilize review props given from parent component.
 */
const JudgeMePreviewBadge: React.FC<PropTypes> = ({
  hideNumberOfReviews = false,
  numberDisplayType = 'unit',
  productId,
  starSize = 'medium',
  onClick,
}) => {
  const { judgeMePublicToken, shopifyDomain } = useConfig();
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeOut, setIsTimeout] = useState(false);
  const { reviews } = useReviewsCacheStore();
  const { testData5970, testData5971 } = useReviewsTest();

  const review = reviews[productId];

  const starSizeClass = useMemo(() => {
    if (starSize === 'small') return 'h-5 w-5';
    if (starSize === 'medium') return 'h-6 w-6';
  }, [starSize]);

  const productData = useMemo(() => {
    if (testData5971 && testData5971.productData[productId]) {
      return testData5971.globalReviewCount;
    } else if (testData5970 && testData5970[productId]) {
      return testData5970[productId];
    }
    return null;
  }, [productId, testData5970, testData5971]);

  setTimeout(() => {
    setIsTimeout(true);
  }, 2000);

  // When used on CDP in which there are too many occurrences, judgeMe api returns 429 error.
  // Refetch after error interval to show the value even after first error.
  const { data: productJudgeMe } = useSWR(
    { ...review, productId, isTimeOut },
    async (arg) => {
      if (arg.ratingCount && arg.reviewsCount) {
        return { ratingCount: arg.ratingCount, reviewsCount: arg.reviewsCount };
      } else if (isTimeOut) {
        const rawReviews = await getJudgeMeRatings({
          externalIds: [arg.productId],
          judgeMePublicToken,
          shopifyDomain,
        });
        if (!rawReviews) {
          return undefined;
        }
        const parsed = parseReviews({ rawReviews });
        return Object.values(parsed)[0];
      }
    },
    {
      errorRetryCount: 2,
      onSuccess: () => setIsLoading(false),
    }
  );

  if (isLoading && !productJudgeMe) return null;

  if (!productJudgeMe) return null;
  const { ratingCount, reviewsCount } = productJudgeMe;

  const rating = Number(productData?.ratingCount ?? ratingCount) || 0;
  let fullStars = Math.floor(rating);
  let halfStar = 0;
  let offStars = 0;

  if (rating % 1 >= 0.25 && rating % 1 <= 0.75) {
    halfStar = 1;
    offStars = 5 - fullStars - halfStar;
  } else if (rating % 1 > 0.74) {
    fullStars += 1;
    offStars = 5 - fullStars;
  } else {
    offStars = 5 - fullStars;
  }

  if (Number(reviewsCount) === 0) return null;

  const content = (
    <>
      <div className="flex text-yellow-400">
        {[...Array(fullStars)].map((_, i) => (
          <IconStarFilled key={i} className={cn(starSizeClass)} />
        ))}
        {halfStar ? <IconStarHalf className={cn(starSizeClass)} /> : null}
        {[...Array(offStars)].map((_, i) => (
          <IconStar
            key={i + fullStars + halfStar}
            className={cn(starSizeClass)}
          />
        ))}
      </div>
      <span className="ml-1">
        {(Number(productData?.ratingCount ?? ratingCount) || 0).toFixed(2)}
      </span>
      {!hideNumberOfReviews && (
        <span className="ml-1.5">
          {numberDisplayType === 'unit' ? (
            <FormattedMessage
              id="pdp:product-title:ratings"
              values={{
                count: productData?.reviewsCount ?? reviewsCount,
              }}
            />
          ) : (
            <>{`(${productData?.reviewsCount ?? reviewsCount})`}</>
          )}
        </span>
      )}
    </>
  );

  const className = 'flex items-center text-sm text-text-secondary';

  return onClick ? (
    <button className={className} data-test="judge-me" onClick={onClick}>
      {content}
    </button>
  ) : (
    <div className={className} data-test="judge-me">
      {content}
    </div>
  );
};
export default JudgeMePreviewBadge;
