import { CollectionProductResponse, fetchProductDetails } from 'api/product';
import { PetTag } from 'constants/product';
import {
  CartUpsellProductDetails,
  CrossSellProductDetails,
} from 'interfaces/product';

/**
 * Check if it is possible to activate a subscription for the product
 */
export const isSubscriptionEnabled = (
  productTags: Array<string> | string,
  productId: number
): boolean => {
  typeof productTags === 'string' &&
    (productTags = productTags.split(',').map((tag) => tag.trim()));
  // this array contains the IDs of the products for which the subscription is disabled no matter what, even if the products contain the tag 'SubscriptionEnabled'.
  // This may be due to marketing decisions.
  // e.g. see: https://petsdeli.atlassian.net/browse/PT-3934
  const PRODUCTS_TO_DISABLE_SUBSCRIPTION = [7996787294474, 7996772843786];
  if (PRODUCTS_TO_DISABLE_SUBSCRIPTION.includes(productId)) return false;
  return (productTags || '').includes('SubscriptionEnabled');
};

/**
 * Returns the price per kilo of the product
 */
export const getPricePerKilo = (price: number, variant: any): number => {
  const productWeight =
    variant &&
    (variant.weightUnit === 'GRAMS' ? variant.weight / 1000 : variant.weight);
  return price / productWeight;
};

/**
 * Returns the price minus the selected percentage
 * @param {number }n - The original product price
 * @param {number} p - The percentage to be subtracted
 * @returns {number} - The price minus the percentage
 */
export const minusPercent = (n: number, p: number): number =>
  +(n - n * (p / 100)).toFixed(2);

/**
 * Format the title of the product
 */
export const formatTitle = (title: string): string =>
  title.replace(/\s\(\dweek\)/, '');

export const ProductPetType = {
  /** Product for dog */
  Dog: 'Dog',
  /** Product for cat */
  Cat: 'Cat',
} as const;

export type ProductPetType =
  | (typeof ProductPetType)[keyof typeof ProductPetType]
  | undefined;

/**
 * Identify product pet type by tags
 */
export const getProductPetTypeByTags = (
  tags: Array<string>
): ProductPetType => {
  if (tags.some((tag) => tag === PetTag.Dog)) {
    return ProductPetType.Dog;
  } else if (tags.some((tag) => tag === PetTag.Cat)) {
    return ProductPetType.Cat;
  } else {
    return undefined;
  }
};

/**
 * Transform collection product to cart upsell product data
 * @param {CollectionProductResponse} product - The collection product data
 * @returns {CartUpsellProductDetails} - The upsell product data
 */
export const transformCollectionProductToUpsellProduct = (
  product: CollectionProductResponse,
  cheapestVariant?: boolean
): CartUpsellProductDetails => {
  const variant = cheapestVariant
    ? product.variants.sort(
        (a, b) => Number(a.priceV2.amount) - Number(b.priceV2.amount)
      )[0]
    : product.variants[0];

  // Type guard to check if image has originalSrc
  const hasOriginalSrc = (image): image is { originalSrc: string } => {
    return 'originalSrc' in image;
  };

  return {
    availableForSale: variant.availableForSale,
    handle: product.handle,
    id: product.id,
    image: hasOriginalSrc(variant.image)
      ? variant.image.originalSrc
      : variant.image.src,
    price: Number(variant.priceV2.amount),
    productId: product.id,
    productTitle: product.title,
    productType: product.productType,
    promoteVariantId: product.masterProductId ? product.id : undefined,
    quantity: 1,
    sku: variant.sku,
    tags: product.tags.toString(),
    title: product.title,
    variantId: variant.id,
    variantTitle: variant.title,
  };
};

/**
 * Fetch product details and return the data in the format of the upsell product
 */
export const createCrossSellProductDetails = async (
  handle: string,
  variantId: string
): Promise<CrossSellProductDetails | null> => {
  const productDetails = await fetchProductDetails(handle);

  const targetVariant = productDetails?.product.variants.find(
    (variant) => variant.id === variantId
  );

  if (!productDetails || !targetVariant) return null;

  const returnData: CrossSellProductDetails = {
    availableForSale: targetVariant.availableForSale,
    handle: productDetails?.product.handle,
    price: +targetVariant.priceV2.amount * 100,
    sku: targetVariant?.sku,
    variantId: variantId,
    productId: productDetails?.product.id,
    productTitle: productDetails?.product.title,
    productType: productDetails?.product.productType,
    variantTitle: targetVariant?.title,
    tags: productDetails?.product.tags.toString(),
    quantity: 1,
    description: productDetails?.product.description,
    image: targetVariant.image.originalSrc,
    ...(targetVariant.compareAtPrice && {
      compareAtPrice: +targetVariant.compareAtPrice * 100,
    }),
  };

  return returnData;
};
