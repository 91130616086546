import { CollectionResponse } from 'api/product';
import { SmartCollectionResponse } from 'api/product/getSmartColleciton/types';
import { isSmartCollectionResponse } from 'api/product/getSmartColleciton/utils';
import { SegmentationIds } from 'constants/segmentation';
import { ShopId } from 'constants/shop';
import { FormattedMessage } from 'react-intl';
import { CollectionProduct } from './collection';
import shouldIgnoreSubtitle from './utils/shouldIgnoreSubtitle';

type transformResponseInput = {
  title?: string;
  collection:
    | Omit<
        CollectionResponse,
        | '_updatedAt'
        | '_updated'
        | 'sk'
        | 'pk'
        | 'missing'
        | 'handle'
        | 'published'
        | 'exportedAt'
      >
    | SmartCollectionResponse;
  shopId: ShopId;
};

export const transformCollection = ({
  title,
  collection,
  shopId,
}: transformResponseInput): Collection => {
  const handle = isSmartCollectionResponse(collection)
    ? collection.slug
    : undefined;

  return new Collection({
    title,
    collection,
    shouldIgnoreSubtitle: shouldIgnoreSubtitle({ handle, shopId }),
  });
};

export class Collection {
  private bestSellerTitle?: string;
  public title: string;
  /** Title which should be used in endless cdp */
  public endlessCdpTitle?: string;
  public products: Array<CollectionProduct>;

  public getBestSellerTitle = ({
    segmentation,
  }: {
    segmentation?: SegmentationIds;
  }): React.ReactNode => {
    if (this.bestSellerTitle) {
      return this.bestSellerTitle;
    }

    if (segmentation) {
      return (
        <FormattedMessage
          id={`personalized-ribbon-${segmentation}`}
          values={{ b: (chunks) => <b>{chunks}</b> }}
        />
      );
    }

    return null;
  };

  constructor({
    title,
    collection,
    shouldIgnoreSubtitle = false,
  }: {
    title?: string;
    collection:
      | Omit<
          CollectionResponse,
          | '_updatedAt'
          | '_updated'
          | 'sk'
          | 'pk'
          | 'missing'
          | 'handle'
          | 'published'
          | 'exportedAt'
        >
      | SmartCollectionResponse;
    shouldIgnoreSubtitle?: boolean;
  }) {
    this.products = (
      isSmartCollectionResponse(collection)
        ? collection.items
        : collection.products
    ).map((product) => new CollectionProduct(product, shouldIgnoreSubtitle));

    if (isSmartCollectionResponse(collection)) {
      this.bestSellerTitle = collection.title;
      this.endlessCdpTitle =
        collection.endless_title || collection.title || title;
    } else {
      this.endlessCdpTitle = title || collection.title;
    }
    this.title = title || collection.title;
  }
}
